import React, { FC, useEffect, useRef } from 'react'
import { ComponentType, LabelProps, LayoutComponentProps, TagProps } from '@sport1/types/web'
import { useTheme } from 'styled-components'
import BasicTeaserSkeleton from '@/components/Skeletons/BasicTeaserSkeleton'
import BundesligaHubSkeleton from '@/components/Skeletons/BundesligaHubSkeleton'
import ExternalWebContentSkeleton from '@/components/Skeletons/ExternalWebContentSkeleton'
import HorizontalCardSkeleton from '@/components/Skeletons/HorizontalCardSkeleton'
import TeaserCardSkeleton from '@/components/Skeletons/TeaserCardSkeleton'
import TopTeaserCardSkeleton from '@/components/Skeletons/TopTeaserCardSkeleton'
import TopVideoSkeleton from '@/components/Skeletons/TopVideoSkeleton'
import renderBaseChannelComponent from '@/helpers/renderBaseChannelComponent'
import useInViewport from '@/hooks/useInViewport'
import { getLayout } from '@/pages/api/cms/content/tag/[id]'
import { log } from '@/utils/Logger'
import { BreakPointProps } from '@/helpers/breakpoint'
import { useRedundantTeaserFunctions } from '@/utils/content/RedundantTeaserProvider'
import renderSideColumnComponent from '@/helpers/renderSideColumnComponent'
import EventTeaserCardSkeleton from '@/components/Skeletons/EventTeaserCardSkeleton'

type Props = {
    type: ComponentType
    url: string
    pageTag?: TagProps | LabelProps
    dashboardPosition?: number
    rightColumnComponents?: LayoutComponentProps[]
    isRightColumn?: boolean
}

const AsyncComponent: FC<Props> = ({
    type,
    url,
    pageTag,
    dashboardPosition,
    rightColumnComponents,
    isRightColumn,
}) => {
    const theme = useTheme()
    const [loaded, setLoaded] = React.useState(false)
    const [component, setComponent] = React.useState<LayoutComponentProps | undefined>()
    const initial = useRef(true)
    const ref = useRef<HTMLDivElement>(null)
    const isInViewPort = useInViewport(ref, false, {
        rootMargin: '1500px',
        threshold: 0,
    })
    const { removeTeaserIds } = useRedundantTeaserFunctions()
    // We need to store the original dashboard position, otherwise it will be overwritten
    const dashboardPositionRef = useRef<number | undefined>(dashboardPosition)

    useEffect(() => {
        if (initial.current && isInViewPort) {
            initial.current = false
            getLayout(url)
                .then(response => {
                    const asyncComponent = removeTeaserIds(response, false, pageTag?.contextId)
                        ?.components[0]
                    setComponent(asyncComponent)
                })
                .catch(error => log('AsyncComponent', error, { url }))
                .finally(() => setLoaded(true))
        }
    }, [url, isInViewPort, removeTeaserIds, pageTag?.contextId])

    if (!component && !loaded) {
        let skeleton = <BasicTeaserSkeleton />
        if (type === ComponentType.BUNDESLIGA_HUB) {
            skeleton = <BundesligaHubSkeleton />
        } else if (type === ComponentType.EXTERNAL_WEB_CONTENT) {
            skeleton = <ExternalWebContentSkeleton />
        } else if (type === ComponentType.HORIZONTAL_CARD) {
            skeleton = <HorizontalCardSkeleton />
        } else if (type === ComponentType.TEASER_CARD) {
            skeleton = <TeaserCardSkeleton />
        } else if (type === ComponentType.TOP_TEASER_CARD) {
            skeleton = <TopTeaserCardSkeleton />
        } else if (type === ComponentType.TOP_VIDEO) {
            skeleton = <TopVideoSkeleton />
        } else if (type === ComponentType.EVENT_TEASER_CARD) {
            skeleton = <EventTeaserCardSkeleton />
        } else if (type === ComponentType.PODCAST_TEASER_CARD) {
            skeleton = <HorizontalCardSkeleton />
        }
        return <div ref={ref}>{skeleton}</div>
    }

    if (!component) return null

    // Must add lazy here as the response does not include it
    return isRightColumn
        ? renderSideColumnComponent({
              component: { ...component, lazy: true },
              isRenderedByAsyncComponent: true,
          })
        : renderBaseChannelComponent({
              component: { ...component, lazy: true },
              pageTag,
              isRenderedByAsyncComponent: true,
              lazyDashboardPosition: dashboardPositionRef.current,
              rightColumnComponents,
              theme: theme as BreakPointProps,
          })
}

export default AsyncComponent
