import React, { ReactElement } from 'react'
import { AdType, ComponentType, LayoutComponentProps } from '@sport1/types/web'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import dynamic from 'next/dynamic'
import { outbrainMobileAds } from '../adHelper'
import AdSlot from '@/components/Ads/AdSlot'
import AsyncComponent from '@/components/AsyncComponent'
import NewsFeed from '@/components/NewsFeed'
import ResultTeaser from '@/components/ResultTeaser'
import TopVideoListTeaser from '@/components/TopVideoListTeaser'
import { SELF_LOADING_COMPONENT_TYPES } from '@/helpers/renderBaseChannelComponent'
import { OddsDisclaimerContextProvider } from '@/utils/oddsDisclaimer/OddsDisclaimerProvider'
import { isMobileView } from '@/helpers/pageHelper'
import GenericComponent from '@/components/Generic'

const Outbrain = dynamic(() => import('@/components/Ads/AdSlot/OutbrainAd'))

/**
 * SPORT1 REFRESH TODO: Potential clean up, correct code mistakes, prettify - was a quick setup before the presentation (after going live needed improvements)
 */

const renderSideColumnComponent = ({
    component,
    isLast,
    isRenderedByAsyncComponent = false,
}: {
    component: LayoutComponentProps
    isLast?: boolean
    isRenderedByAsyncComponent?: boolean
}): ReactElement | null => {
    let sideColumnItem
    const { type, componentKey } = component
    const key = `render-side-column-${type}_${componentKey}`
    let hideElement = false

    if (
        component.lazy &&
        SELF_LOADING_COMPONENT_TYPES.includes(component.type) &&
        component.url &&
        !isRenderedByAsyncComponent
    ) {
        return <AsyncComponent key={key} type={component.type} url={component.url} isRightColumn />
    }

    if (
        type === ComponentType.NEWS_FEED_LIST_TEASER ||
        type === ComponentType.NEWS_FEED_TEASER_CARD
    ) {
        sideColumnItem = (
            <NewsFeed
                key={key}
                content={component.content}
                actionButtons={component.actionButtons}
                tags={component.tags}
            />
        )
    } else if (type === ComponentType.TOP_TEASER_LIST) {
        sideColumnItem = <TopVideoListTeaser key={key} teaser={component.content} />
    } else if (type === ComponentType.AD && component.ad?.adType === AdType.DISPLAY) {
        sideColumnItem = <AdSlot key={key} {...component} disableBottomSpace />
    } else if (
        type === ComponentType.AD &&
        component.ad?.adType === AdType.OUTBRAIN_AD &&
        component.ad?.widgetIdentifiers?.web
    ) {
        const dataWidgetId = component.ad.widgetIdentifiers.web
        hideElement = !isMobileView() && outbrainMobileAds.includes(dataWidgetId.toUpperCase())
        sideColumnItem = <Outbrain dataWidgetId={dataWidgetId} key={key} />
    } else if (type === ComponentType.RESULT_TEASER) {
        if (component.content?.length) {
            sideColumnItem = (
                <OddsDisclaimerContextProvider key={key}>
                    <ResultTeaser
                        isRightColumn
                        forceMobileView
                        componentData={component}
                        disableContentSpaceX
                        trackingInteractionLabel="result-teaser"
                    />
                </OddsDisclaimerContextProvider>
            )
        }
    } else if (type === ComponentType.GENERIC) {
        sideColumnItem = <GenericComponent {...component} isRightColumn />
    }
    return hideElement ? null : (
        <NonFlexingContainer
            key={key}
            marginBottom={!isLast && component.type !== 'GENERIC' ? 'spacing-9' : 'spacing-none'}
            testID={key}
        >
            {sideColumnItem}
        </NonFlexingContainer>
    )
}

export default renderSideColumnComponent
